// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}

// rediced padding on bootstrap col
.row.rp3 {
  margin-left: -3px;
  margin-right: -3px;
  [class*="col-"] {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
}
