.site-footer {
	background-color: $cod-grey;
	text-align: center;
	padding: 3rem 0;
	background-image: url(/wp-content/themes/rope_access/dist/images/footer-bg.png);
	background-repeat: no-repeat;
	img {
		margin-bottom: 1rem;
	}
	.footer__text {
		color: #fff;
		max-width: 36rem;
		margin: 0 auto;
	}
	@media screen and (max-width: 1199px) {
		background-image: none;
	}
}

.footer-copyright {
	background-color: $lightning-yellow;
	padding-top: 1rem;
	text-align: left;
	color: $cod-grey;
	border-top: 2px solid #fff;
	p {
		display: inline-block;
		margin-bottom: 1rem;
		@media screen and (max-width: 1199px) {
			display: block;
		}
	}
	a {
		color: $cod-grey;
	}
	@media screen and (max-width: 1199px) {
		text-align: center;
	}
}

.footer-copyright__nav {
	display: inline-block;
	float: right;
	font-size: 0;
	padding-top: 4px;

	a {
		padding: 0 0.5rem;
		border-right: 2px solid rgba(0,0,0,0.1);
		font-size: 1rem;
		line-height: 1;
		margin-bottom: 0.5rem;
		display: inline-block;
		&:last-child {
			border-right: 0;
		}
	}
	@media screen and (max-width: 1199px) {
		display: block;
		float: none;
		padding-bottom: 1rem;
	}
}