/*=============================
=            fonts            =
=============================*/
@font-face {
	font-family: 'Open Sans';
	src: url(/wp-content/themes/rope_access/dist/fonts/OpenSans.eot);
	src: url(/wp-content/themes/rope_access/dist/fonts/OpenSans.eot?#iefix) format('embedded-opentype'),
		url(/wp-content/themes/rope_access/dist/fonts/OpenSans.woff) format('woff'),
		url(/wp-content/themes/rope_access/dist/fonts/OpenSans.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto-Black';
	src: url(/wp-content/themes/rope_access/dist/fonts/Roboto-Black.eot);
	src: url(/wp-content/themes/rope_access/dist/fonts/Roboto-Black.eot?#iefix) format('embedded-opentype'),
		url(/wp-content/themes/rope_access/dist/fonts/Roboto-Black.woff) format('woff'),
		url(/wp-content/themes/rope_access/dist/fonts/Roboto-Black.ttf) format('truetype');
	font-weight: 900;
	font-style: normal;
}
/*=====  End of fonts  ======*/

/*==================================
=            typography            =
==================================*/

p,
a {
	font-family: 'Open Sans';
}

a.btn,
.btn {
	font-family: 'Roboto-Black';

}
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
	color: $cod-grey;
}
h1,
h2,
h3,
h4 {
	font-family: 'Roboto-Black';
	color: $cod-grey;
}
h1 {
	font-size: 3.5rem;
}
h2 {
	font-size: 3rem;
}
h3 {
	font-size: 2rem;
}


h5 {
	font-family: 'Open Sans';
	font-weight: bold;
	&.subtitle {
		text-transform: uppercase;
		font-weight: bold;
		color: $lightning-yellow;
	}
}


/*=====  End of typography  ======*/

/*==============================================
=            slick slider overrides            =
==============================================*/

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('/wp-content/themes/rope_access/dist/fonts/slick.eot');
  
  src: url('/wp-content/themes/rope_access/dist/fonts/slick.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/rope_access/dist/fonts/slick.woff') format('woff'),
  url('/wp-content/themes/rope_access/dist/fonts/slick.ttf') format('truetype'),
  url('/wp-content/themes/rope_access/dist/fonts/slick.svg#slick') format('svg');
}

.slick-loading .slick-list {
  background: #fff url('/wp-content/themes/rope_access/dist/images/ajax-loader.gif') center center no-repeat;
}

/*=====  End of slick slider overrides  ======*/

/*=========================================
=            swipebox overrides            =
=========================================*/

#swipebox-slider .slide-loading {
  background: url(/wp-content/themes/rope_access/dist/images/loader.gif) no-repeat center center;
}
#swipebox-prev,
#swipebox-next,
#swipebox-close {
  background-image: url(/wp-content/themes/rope_access/dist/images/icons.png);
  background-repeat: no-repeat;
  border: none !important;
  text-decoration: none !important;
  cursor: pointer;
  width: 50px;
  height: 50px;
  top: 0;
}

/*=====  End of package overrides  ======*/

