/*==============================
=            navbar            =
==============================*/

@media screen and (min-width: 768px) {
	.navbar-toggleable-sm {
	  display: inline-block !important;
	}
}
nav.navbar {
	padding-left: 0;
	padding-right: 0;
}
.navbar-brand {
	width: 100%;
	max-width: 300px;
	margin-right: 3rem;
	@media screen and (max-width: 575px) {
		max-width: 220px;
	}
}
.navbar-nav {
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flex;
	display: -o-inline-flex;
	display: inline-flex;
}

.burger-icon {
	stroke: #fff;
	height: 2rem;
	width: 2rem;
}
.navbar-toggler {
	padding: 0;
	outline: 0 !important;
	position: absolute;
	right: 0;
	@media screen and (min-width: 1199px) {
		display: none;
	}
}

.fixed-navbar {
	background-color: rgba(0,0,0,0.7);
	position: absolute;
	width: 100%;
	z-index: 2;
	@media screen and (max-width: 575px) {
		background-color: #000;
	}
}
.nav-item.active a {
	color: $lightning-yellow;
}
.nav-link,
.nav-link:focus {
	text-transform: uppercase;
	font-family: 'Roboto-Black';
	color: #fff;
	outline: none;
	&:hover {
		color: $lightning-yellow;
	}
}

@media screen and (min-width: 992px) {
	.navbar-toggleable-md .navbar-nav .nav-link {
	  padding-right: 1rem;
	  padding-left: 1rem;
	}
}

.navbar .btn {
	border-color: #fff;
	color: #fff;
	margin-left: 3rem;
	&:hover {
		background-color: $lightning-yellow;
		color: #000;
		border-color: $lightning-yellow;
	}
	@media screen and (max-width: 1199px) {
		display: none;
	}
}
/*=====  End of navbar  ======*/

/*===================================
=            page-header            =
===================================*/

.page-header {
	background-image: url(/wp-content/themes/rope_access/dist/images/pageHeaderBG.jpg);
	padding-top: 8.5rem;
	padding-bottom: 3.5rem;
	background-size: cover;
	background-position: 50% 30%;
	h1 {
		color: #fff;
	}
}

/*=====  End of page-header  ======*/

/*===============================================
=            video background header            =
===============================================*/

.video-header {
  position: relative;
  overflow: hidden;
  height: 50rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/wp-content/themes/rope_access/dist/images/pageHeaderBG.jpg);
  background-size: cover;
  background-position: 50%;
  svg {
  	fill: #fff;
  	position: absolute;
  	bottom: 5rem;
  	left: 50%;
    margin-left: -9px;
    z-index: 10;
  }
}
.video-bg,
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  .play-text {
		font-size: 3rem;
		margin-right: 2rem;
		color: #fff;
		font-family: 'Roboto-Black';
		span {
			color: $lightning-yellow;
		}
		@media screen and (max-width: 575px) {
			display: none;
		}
	}
}
.video-header .video-bg,
.video-header .video-overlay {
	@media screen and (max-width: 991px) {
		display: none;
	}
}
.video-overlay {
  background: rgba(0,0,0,0.5);
  pointer-events: none; /* Allows right clicking on the video to pause etc */
}
.video-bg video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-header__content {
  z-index: 10;
  color: #fff;
  text-align: center;
  width: 90%;
  h1{
  	color: #fff;
  	margin-bottom: 2rem;
  	font-size: 6rem;
  	@media screen and (max-width: 575px) {
  		font-size: 3rem;
  	}
  }
  
}

.video-header__buttons {
	.btn {
  	margin-top: 2rem;
  	width: 12rem;
  	@media screen and (max-width: 575px) {
  		width: 100%;
  	}
  }
  .btn+.btn {
  	border-left: 0;
  	color: #fff;
  	@media screen and (max-width: 575px) {
  		border-left: 1px solid;
  	}
  	&:hover {
  		color: #000;
  		background-color: $lightning-yellow;
  		border-color: $lightning-yellow;
  	}
  }
}

.video-header__buttons .btn:first-child:hover {
	background-color: $lightning-yellow;
	border-color: $lightning-yellow;
}

.video-header__badge {
	background-color: $lightning-yellow;
	padding: 1rem 1.5rem;
	border-radius: 50px;
	font-family: 'Open Sans';
	color: #000;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1.125rem;
	width: 19rem;
	display: inline-block;
	margin-bottom: 2rem;
	@media screen and (max-width: 575px) {
		width: auto;
	}
}

/*=====  End of video background header  ======*/

/*=======================================
=            welcome section            =
=======================================*/

.welcome-section {
	text-align: center;
	padding: 4rem 0;
	background-image: url("/wp-content/themes/rope_access/dist/images/welcome-badge-bg.png");
	background-repeat: no-repeat;
	background-position: 50% 34%;
	h5,
	h1 {
		margin-bottom: 2.5rem;
	}
	@media screen and (max-width: 575px) {
		background-image: none;
	}
}

.about .welcome-section {
	padding-bottom: 2rem;
	@media screen and (max-width: 991px) {
		padding-bottom: 0;
	}
}

.skills .welcome-section {
	padding-bottom: 1rem;
}

/*=====  End of welcome section  ======*/



