/*===================================================
=            Request a Call back section            =
===================================================*/

.request-call {
	background-image: url(/wp-content/themes/rope_access/dist/images/rac_bg.jpg);
	background-size: cover;
	background-position: 50%;
	padding: 4rem 0;
	padding-bottom: 5px;
	position: relative;
	z-index: 10;
	h5 {
		color: $lightning-yellow;
		text-transform: uppercase;
		margin-bottom: 1rem;
	}
	h1 {
		color: #fff;
		margin-bottom: 2rem;
		span {
			color: $lightning-yellow;
		}
	}
	h1 + p {
		color: #fff;
		margin-bottom: 2rem;
		line-height: 2rem;
	}
}

.request-call__contact {
	margin-bottom: 1rem;
	svg {
		fill: $lightning-yellow;
		margin-right: 2rem;
		margin-bottom: -0.4rem;
	}
	a {
		color: #fff;
		font-weight: bold;
		font-size: 1.5rem;
		@media screen and (max-width: 400px) {
			font-size: 1rem;
		}
	}
}

.request-call .wpcf7-form {
	background-color: #fff;
	padding: 3rem;
	padding-bottom: 1rem;
	@media screen and (max-width: 575px) {
		padding: 1.7rem;
		padding-bottom: 0;
	}
}

/*=====  End of Request a Call back section  ======*/

/*===========================================
=            our clients section            =
===========================================*/

.our-clients {
	text-align: center;
	background-color: $wild-sand;
	padding: 4rem 0;
	img {
		padding: 4rem 0rem;
	}
	p {
		color: $dove-grey;
		padding: 1rem 0;
	}
	.slick-slide {
		height: 13rem
	}
	.logo-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

/*=====  End of our clients section  ======*/

/*======================================
=            two butons cta         =
======================================*/

.two-buttons {
	.btn {
		width: 12rem;
	}
	.btn + .btn {
		margin-left: 1rem;
	}
}

@media screen and (max-width: 575px) {
	.two-buttons {
	 .btn {
			display: block;
		}
		.btn + .btn {
			margin-top: 1rem;
			margin-left: 0;
		}
	}

	.two-buttons--center {
		.btn {
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}
}

/*=====  End of two butons cts  ======*/


/*==========================================
=            we deliver results            =
==========================================*/

.deliver-results {
	padding: 3rem 0;

	.subtitle {
		padding-top: 2rem;
		margin-bottom: 0;
	}
	h1 {
		padding-top: 2rem;
	}
	.deliver-results__content {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

.delivery-results__content-wrapper {
	background-image: url(/wp-content/themes/rope_access/dist/images/abseil-badge-bg.png);
	background-repeat: no-repeat;
	background-position: 25% 50%;
	background-size: contain;
}

.service .deliver-results {
	padding-top: 0;
	padding-bottom: 5rem;
	position: relative;
	z-index: 10;
	background-color: #fff;
	.delivery-results__content-wrapper {
		background-image: none;
	}
}

/*=====  End of we deliver results  ======*/

/*==========================================
=            statistics section            =
==========================================*/

.statistics-section {
	background-image: url("/wp-content/themes/rope_access/dist/images/statistics-bg.jpg");
	background-size: cover;
	background-position: 50%;
	padding: 4rem 0;
	h2,
	h3 {
		color: #fff;
		margin-bottom: 0;
		span {
			color: $lightning-yellow;
		}
	}
}
.statistics-wrapper {
	padding-top: 0.4rem;
}

.statistic__stat {
	text-align: center;
	border-right: 1px solid $jumbo;
	h2 {
		font-size: 2.5rem;
	}
	&:last-child {
		border-right: 0;
	}
	p {
		text-transform: uppercase;
		color: $lightning-yellow;
		margin-bottom: 0;
	}
}

@media screen and (max-width: 1199px) {
	.statistics-section {
		text-align: center;
	}
	.statistics-wrapper {
		padding-top: 2rem;
	}
}

@media screen and (max-width: 767px) {
	.statistic__stat {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid $jumbo;
		&:nth-child(2) {
			border-right: 0;
		}
		&:nth-child(3),
		&:last-child {
			border-bottom: 0;
		}
	}
}

@media screen and (max-width: 575px) {
	.statistic__stat {
		border-right: 0 !important;
		border-bottom: 1px solid $jumbo !important;

		&:last-child {
			border-bottom: 0 !important;
		}
	}
}

@media screen and (max-width: 480px) {
	.statistic__stat {
		max-width: 250px;
		margin: 0 auto;
	}
}

/*=====  End of statistics section  ======*/

/*========================================
=            services section            =
========================================*/

.services {
	padding: 3rem 0;
	img {
		margin-bottom: 1rem;
	}
	h3 {
		margin-bottom: 1rem;
		font-size: 1.7rem;
	}
	.service__content {
		margin-bottom: 1rem;
	}
	a {
		display: inline-block;
		color: $cod-grey;
		font-weight: bold;
	}
	.service__read-more-btn {
		margin-bottom: 2rem;
		display: inline-block;
		border-bottom: 1px solid $lightning-yellow;
	}
	svg {
		fill: $lightning-yellow;
	}
}
.service__content-wrapper {
  margin-bottom: 1rem;
}
.service__read-more-content {
	display: none;
}

/*=====  End of services section  ======*/

/*=============================================
=            two column with video            =
=============================================*/

.two-col-video {
	padding: 4rem 0;
	background-color: $wild-sand;
	background-image: url("/wp-content/themes/rope_access/dist/images/two-col-video-bg.png");
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: 20%;
	h1 {
		margin-bottom: 2rem;
	}
	.two-buttons {
		margin-bottom: 2rem;
	}
	.two-col-video__content {
		margin-bottom: 2rem;
	}
	.video-wrapper {
		position: relative;
		top: 3rem;
		svg {
			fill: $lightning-yellow;
		}
	}
	video {
		display: block;
	}
}

@media screen and (max-width: 991px) {
	.two-col-video .video-wrapper {
		top: 0;
	}
}

/*=====  End of two column with video  ======*/

.video-wrapper svg {
	@media screen and (max-width: 575px) {
		width: 4rem;
	}
}


/*==========================================
=            three step process            =
==========================================*/
.skills .three-step-process {
	margin-top: 6rem;
}
.three-step-process {
	padding: 4rem 0;
	text-align: center;
	background-image: url("/wp-content/themes/rope_access/dist/images/process-bg.jpg");
	background-size: cover;
	background-position: 50%;
	color: #fff;
	h1 {
		margin-bottom: 1rem;
		color: #fff;
	}
	h3 {
		color: #fff;
	}
	p {
		margin-bottom: 0;
	}
	img {
		margin-bottom: 1rem;
	}
	.video-header__buttons {
		margin-bottom: 3rem;
	}
	svg {
		fill: $lightning-yellow;
		position: absolute;
    top: 26%;
    right: -46px;
	}
	.btn-yellow-outline:focus {
		color: #fff;
	}
}

@media screen and (max-width: 991px) {
	.three-step-process {
		svg {
			transform: rotate(90deg);
			position: relative;
			top: 0;
			left: 0;
			margin-top: 5rem;
		}
		img {
			padding-top: 5rem;
		}
		.three-step-process__step:first-child img {
			padding-top: 0;
		}
	}
}

/*=====  End of three step process  ======*/

/*==================================
=            google map            =
==================================*/

#map {
	min-height: 400px;
	margin-top: 2rem;
	a {
		color: $lightning-yellow;
	}
}

/*=====  End of google map  ======*/

/*=======================================
=            contact methods            =
=======================================*/

.contact-page-details {
	padding-top: 2rem;
}

.contact-method {
	padding-left: 4rem;
	padding-bottom: 2rem;
	position: relative;
	max-width: 18.75rem;
	text-align: left;
	svg {
		fill: $lightning-yellow;
		position: absolute;
		left: 0;
		top:0;
	}
}

.contact-method__detail {
	color: $cod-grey;
	font-weight: bold;
	a,
	a:hover {
		color: $cod-grey;
	}
	span {
		color: $lightning-yellow;
	}
	&--pt {
		padding-top: 1rem;
	}
}

.contact-method__detail--note {
	font-weight: normal;
	font-style: italic;
}

.contact-method--center {
	margin-left: auto;
	margin-right: auto;
}

/*=====  End of contact methods  ======*/


/*=========================================
=            meet team section            =
=========================================*/

.meet-team {
	padding: 4rem 0;
	text-align: center;
}

.team-member {
	padding-top: 3rem;
	img {
		margin-bottom: 1.5rem;
	}
	img + img {
		display: none;
	}
	h5 {
		color: $lightning-yellow;
	}
	.team-member__description {
		padding-top: 1rem;
		max-width: 21rem;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 2rem;
	}
	.contact-method {
		max-width: 15.75rem;
	}
}
.team-member__image-wrapper {
	display: inline-block;
	img {
		margin-bottom: 1.5rem;
	}
	img + img {
		display: none;
	}
	&:hover {
		img + img {
			display: inline;
		}
		img:first-child {
			display: none;
		}
	}
}

/*=====  End of meet team section  ======*/

/*=====================================================
=            two column content with image            =
=====================================================*/

.tccwi {
	padding: 3rem 0;
	text-align: center;
	&:first-child {
		padding-top: 0;
	}
	&--grey {
		background-color: $wild-sand;
	}
}

.tccwi__content {
	display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1.5rem 0;
}

/*=====  End of two column content with image  ======*/


/*================================================
=            full width video section            =
================================================*/

.full-width-video-bg {
	position: relative;
	height: 28.125rem;
	overflow: hidden;
	svg {
		fill: $lightning-yellow;
	}
	video {
		height: 28.125rem;
		z-index: 0;
	}
}

/*=====  End of full width video section  ======*/

/*===============================================
=            hygiene control section            =
===============================================*/

.hygiene-control {
	padding: 4rem 0;
	text-align: center;
	background-color: $wild-sand;
	background-image: url(/wp-content/themes/rope_access/dist/images/two-col-video-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 20%;
}
.hygiene-control__content {
	padding: 3rem 0;
}


/*=====  End of hygiene control section  ======*/

/*===============================================
=            Provide On Site Section            =
===============================================*/

.provide-services {
	padding: 3rem 0;
	text-align: center;
	p {
		padding-top: 3rem;
		margin-bottom: 3rem;
	}

	.numbered-service {
		text-align: left;
		max-width: 25rem;
		margin-left: auto;
		margin-right: auto;
	}

}


/*=====  End of Provide On Site Section  ======*/

/*========================================
=            numbered service            =
========================================*/

.numbered-service {
	margin-bottom: 2rem;

	.numbered-service__number {
		display: flex;	
		width: 2rem;
		height: 2rem;
		border: 1px solid $lightning-yellow;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin-right: 2rem;
		position: absolute;
	}
	.numbered-service__name {
		text-transform: uppercase;
		font-weight: bold;
		padding-left: 4rem;
		padding-top: 0.25rem;
	}
}
/*=====  End of numbered service ======*/

/*=============================================
=            provide image gallery            =
=============================================*/

.provide-gallery {
	padding-top: 3rem;
	@media screen and (max-width: 575px) {
		.row.rp3 [class*="col-"] {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
}
.provide-gallery__image {
	padding-bottom: 56.25%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 6px;
}

/*=====  End of provide image gallery  ======*/


/*==============================
=            skills            =
==============================*/
.skill {
	text-align: center;
	padding-top: 3rem;
	@media screen and (max-width: 991px) {
		.two-buttons {
		 .btn {
				display: block;
			}
			.btn + .btn {
				margin-top: 1rem;
				margin-left: 0;
			}
		}
		.two-buttons--center {
			.btn {
				margin-left: auto !important;
				margin-right: auto !important;
			}
		}
	}
}
.skill__image {
	padding-bottom: 56.25%;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	margin-bottom: 2rem;
}

/*=====  End of skills  ======*/

/*=======================================
=            project gallery            =
=======================================*/
.gallery {
	margin-bottom: 4rem;
}
.provide-gallery__image {
	position: relative;
	padding-bottom: 75%;
}
.gallery__image-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: $lightning-yellow;
	opacity: 0;
	color: $cod-grey;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	&:hover {
		opacity: 0.8;
	}
	h3 {
		color: $cod-grey;
	}
}

.gallery-filter {
	margin-bottom: 2rem;
	text-align: center;
	.btn {
		width: 11rem;
		box-shadow: none !important;
		outline: none !important;
	}
	.btn.active {
		background-color: $lightning-yellow;
	}
}

.projects-teaser {
	text-align: center;
	padding: 4rem 0;
	.btn {
		margin-top: 2rem;
	}
	.projects-teaser__content {
		padding: 2rem 0;
	}
}


@media screen and (max-width: 991px) {
	.gallery-filter .btn {
		width: 8rem;
	}
}
@media screen and (max-width: 767px) {
	.gallery-filter {
		display: none;
	}
	.gallery__image-overlay h3 {
		font-size: 1.5rem;
	}
}

/*=====  End of project gallery  ======*/













