// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;

$lightning-yellow: 			#fdc513;

// greys
$cod-grey: 							#111111;
$wild-sand: 						#f6f6f6;
$dove-grey: 						#676767;
$jumbo:									#7A7A7C;
