.btn-yellow {
	background-color: $lightning-yellow;
	color: #000 !important;
	&:hover {
		color: #000;
		background-color: transparent;
		border-color: $lightning-yellow;
	}
}
.btn-yellow-outline {
	border: 1px solid $lightning-yellow;
	&:hover {
		background-color: $lightning-yellow;
		color: #000;
	}
}
.btn-outline-white {
	border: 1px solid #fff !important;
	color: #fff !important;
}
.btn {
	text-transform: uppercase;
	border-radius: 0;
	color: $cod-grey;
	&:focus {
		outline: none;
		box-shadow: none;
	}
}
.btn-lg {
	font-size: 1rem;
}